import React from 'react'

import clsx from 'clsx'
import { graphql, PageProps } from 'gatsby'

import { Page, values } from '@karakuri-ai/faq-component'

import IndexLayout from '../layouts'
import { loadDictionary } from '../utils/dictionary'
import { Data, SearchParams } from '../widgets/interface'
import { renderIndexPageWidget } from '../widgets/pages'
import { SEOWidget as SEO } from '../widgets/SEOWidget'

type Props = PageProps<Data, Record<string, unknown>, SearchParams>
function IndexPage({ data, location }: Props) {
  React.useEffect(() => {
    loadDictionary()
  }, [])
  const widgetProps = {
    data,
    location,
  }
  const layout = data.settingYaml.layout || 'one'
  const left = layout === 'one' ? [] : values(data.settingYaml.layoutLeft, [])
  const right = layout === 'three' ? values(data.settingYaml.layoutRight, []) : []
  const main = values(
    data.settingYaml.layoutMain,
    (left.length === 0 &&
      right.length === 0 && [
        { id: 'search', type: 'search' },
        { id: 'important', type: 'important' },
        { id: 'frequent', type: 'frequent' },
        { id: 'category', type: 'category' },
      ]) ||
      []
  )
  const title = data.settingYaml?.words?.index?.title ?? 'よくあるご質問 TOP'
  const headTitle =
    data.settingYaml?.words?.index?.headTitle ?? (data.settingYaml.title || 'よくあるご質問')
  return (
    <IndexLayout className="faq-index-layout">
      <SEO pathname={location.pathname} title={headTitle} />
      <Page className="faq-content">
        <div className="faq-main-content">
          <div className="faq-header">
            <h2>{title}</h2>
          </div>
          {renderIndexPageWidget(main, widgetProps)}
        </div>
        <div className={clsx('faq-left-content', { 'faq-no-content': left.length === 0 })}>
          {renderIndexPageWidget(left, widgetProps)}
        </div>
        <div className={clsx('faq-right-content', { 'faq-no-content': right.length === 0 })}>
          {renderIndexPageWidget(right, widgetProps)}
        </div>
      </Page>
    </IndexLayout>
  )
}
export default IndexPage

export const query = graphql`
  fragment SubRecursive on SettingYamlCategorySub {
    id
    title
    sub {
      ...Sub
      sub {
        ...Sub
        sub {
          ...Sub
          sub {
            ...Sub
            sub {
              ...Sub
              sub {
                ...Sub
                sub {
                  ...Sub
                  sub {
                    ...Sub
                    sub {
                      ...Sub
                      sub {
                        ...Sub
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment Sub on SettingYamlCategorySub {
    id
    title
  }

  fragment Layout on Layout {
    id
    title
    type
    data
    icons {
      id
      title
      reference
      image
    }
  }

  query IndexData {
    settingYaml(ignore: { ne: true }) {
      botId
      title
      layout
      layoutMain {
        ...Layout
      }
      layoutLeft {
        ...Layout
      }
      layoutRight {
        ...Layout
      }
      informations {
        id
        title
      }
      importants {
        id
        title
      }
      categories {
        id
        title
        sub {
          ...SubRecursive
        }
      }
      words {
        index {
          title
          headTitle
        }
        search {
          placeholder
          resultMessage {
            success
            error
          }
        }
      }
      waitMs {
        complete
        search
      }
      useAutoSearch
      useSearchWithCategory
      useKnowledgeSearch
      searchType
      queryThreshold
    }
  }
`
